import { reactive, computed } from "vue";
import { v4 as uuid } from "uuid";

const DEFAULT_TIMEOUT = 3000;
const notifications = reactive(new Set<Notification>());

// TODO: deberia ser una clase y el constructor setear los valores por defecto
export interface Notification {
  id?: string;
  title: string;
  text?: string;
  theme?: "success" | "error";
  timeout?: number;
  closable?: boolean;
}

export function useNotifications() {
  const notify = (notification: Notification) => {
    notification.id ??= uuid();
    notification.theme ??= "success";
    notification.timeout ??= DEFAULT_TIMEOUT;
    notification.closable ??= true;

    notifications.add(notification);

    if (notification.timeout) {
      setTimeout(() => {
        remove(notification);
      }, notification.timeout);
    }
  };

  const remove = (notification: Notification) => {
    notifications.delete(notification);
  };

  const removeAll = () => {
    notifications.clear();
  };

  const isEmpty = computed(() => {
    return notifications.size === 0;
  });

  return {
    notify,
    remove,
    removeAll,
    notifications,
    isEmpty,
  };
}
